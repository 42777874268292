// * React Hooks
import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// * Routes
import * as URL from "./Routes";
import ProtectedRoute from "src/helpers/ProtectedRoute";

// * Pages
const Login = lazy(() => import("../views/pages/Login/Login"));
const Register = lazy(() => import("../views/pages/register/Register"));
const Page404 = lazy(() => import("../views/pages/page404/Page404"));
const Page505 = lazy(() => import("../views/pages/page500/Page500"));
const Dashboard = lazy(() => import("../views/dashboard/Dashboard"));

const UserTypeList = lazy(() => import("../views/pages/UserType/List"));
const AddUserType = lazy(() =>
  import("../views/pages/UserType/AddUserType/AddUserType")
);
const EditUserType = lazy(() =>
  import("../views/pages/UserType/EditUserType/EditUserType")
);

const RoomTypeList = lazy(() => import("../views/pages/RoomType/List"));
const AddRoomType = lazy(() =>
  import("../views/pages/RoomType/AddRoomType/AddRoomType")
);
const EditRoomType = lazy(() =>
  import("../views/pages/RoomType/EditRoomType/EditRoomType")
);

const UserList = lazy(() => import("../views/pages/Users/List"));
const AddUser = lazy(() => import("../views/pages/Users/Add/AddUser"));
const EditUser = lazy(() => import("../views/pages/Users/Edit/EditUser"));
const PermissionView = lazy(() => import("../views/pages/Users/View/View"));

const HotelList = lazy(() => import("../views/pages/Hotel/List"));
const AddHotel = lazy(() => import("../views/pages/Hotel/Add/AddHotel"));
const EditHotel = lazy(() => import("../views/pages/Hotel/Edit/EditHotel"));
const HotelView = lazy(() => import("../views/pages/Hotel/View/View"));
const ViewHotelRoom = lazy(() =>
  import("../views/pages/Hotel/EditRoom/EditRoom")
);

const ReservationList = lazy(() => import("../views/pages/Reservation/List"));
const BulkReservation = lazy(() =>
  import("../views/pages/Reservation/Bulk/Bulk")
);
const ReservationView = lazy(() =>
  import("../views/pages/Reservation/View/View")
);
const EditReservation = lazy(() =>
  import("../views/pages/Reservation/Edit/EditReservation")
);
const CheckInCheckOutList = lazy(() =>
  import("../views/pages/Reservation/CheckInCheckOut/List")
);

const AddReservation = lazy(() =>
  import("../views/pages/Reservation/Add/AddReservation")
);

const AddHotelReservation = lazy(() =>
  import("../views/pages/Reservation/AddHotelReservation/AddHotelReservation")
);
// * Page Layout Component
const Layout = lazy(() => import("../layout/Layout"));

// * Loader
const Loader = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

export default function Router() {
  const Navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === "/") Navigate("/login");
  });
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path={URL.Login}
          element={
            <ProtectedRoute accessBy="non-authenticated">
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.Dashboard}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<Dashboard />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.UserTypeList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<UserTypeList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddUserType}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddUserType />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditUserTypeId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditUserType />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.RoomTypeList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<RoomTypeList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddRoomType}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddRoomType />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditRoomTypeId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditRoomType />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.UserList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<UserList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddUser}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddUser />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.EditUserId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditUser />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewPermission}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<PermissionView />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.HotelList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<HotelList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddHotel}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddHotel />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.EditHotelId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditHotel />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewHotelId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<HotelView />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewHotelRoomId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<ViewHotelRoom />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ReservationList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<ReservationList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.BulkReservation}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<BulkReservation />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewReservationId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<ReservationView />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditReservationId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditReservation />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.CheckInCheckOutList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<CheckInCheckOutList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddReservation}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddReservation />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddHotelReservationId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddHotelReservation />} />
            </ProtectedRoute>
          }
        />

        <Route path={URL.Register} element={<Register />} />
        <Route path={URL.Page500} element={<Page505 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
}
