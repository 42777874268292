export const Register = "/register";
export const Login = "/login";
export const Page404 = "/404";
export const Page500 = "/505";
export const Dashboard = "/dashboard";

export const UserTypeList = "/usertype/list";
export const AddUserType = "/usertype/add";
export const EditUserType = "/usertype/edit";
export const EditUserTypeId = "/usertype/edit/:id";

export const RoomTypeList = "/roomtype/list";
export const AddRoomType = "/roomtype/add";
export const EditRoomType = "/roomtype/edit";
export const EditRoomTypeId = "/roomtype/edit/:id";

export const UserList = "/user/list";
export const AddUser = "/user/add";
export const EditUser = "/user/edit";
export const EditUserId = "/user/edit/:id";
export const ViewPermission = "/user/permission/:id";

export const HotelList = "/hotel/list";
export const AddHotel = "/hotel/add";
export const EditHotel = "/hotel/edit";
export const EditHotelId = "/hotel/edit/:id";
export const ViewHotel = "/hotel/details";
export const ViewHotelId = "/hotel/details/:id";
export const ViewHotelRoom = "/hotel/rooms";
export const ViewHotelRoomId = "/hotel/rooms/:hotelId/:id";

export const ReservationList = "/reservation/list";
export const BulkReservation = "/reservation/bulk";
export const ViewReservation = "/reservation/details";
export const ViewReservationId = "/reservation/details/:id";
export const AddReservation = "/reservation/add";
export const EditReservation = "/reservation/edit";
export const EditReservationId = "/reservation/edit/:id";
export const CheckInCheckOutList = "/reservation/checkIncheckOut";
export const AddHotelReservation = "/reservation/hotel/add";
export const AddHotelReservationId = "/reservation/hotel/add/:id";
